const BHCrossSell = {
	BHCrossSell: {
		defaultProps: {
			showTitle: true,
			skeletonProps: { columnGap: 2 },
			sliderProps: {
				breakpoints: {
					xs: {
						spaceBetween: 0,
					},
					md: {
						spaceBetween: 0,
					},
				},
				controlsPosition: 'top-right',
				spaceBetween: 0,
			},
		},
	},
}

export default BHCrossSell
