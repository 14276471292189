const BHTileBase = {
	BHTileBase: {
		defaultProps: {
			wishListProps: {
				position: 'outer',
				variant: 'icon',
				iconProps: {
					fontSize: 'medium',
					color: 'secondary',
				},
			},
			addToCartProps: {
				position: 'inner',
				variant: 'icon',
				iconProps: {
					fontSize: 'medium',
					color: 'secondary',
				},
			},
		},
	},
}

export default BHTileBase
